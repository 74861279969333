#inner .rs-menu li>a:hover, .mega-menu li p a:hover, .nav-menu>.current-menu-ancestor>a, .nav-menu>.current-menu-item>a, .nav-menu>.current_page_item>a, .nav-menu>li>a:hover, .nav-menu ul .current-menu-item a, .nav-menu ul .current_page_item a {
    color: white;
}



.brand-logo-area {
    .container {
        ul {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            li {
                padding: 0;
                margin: 0;
            }
        }
    }
}

.restoran_barbakan {
    .container {
        
    }
}

.mfp-title {
    display: none;
}