    /*
    Flaticon icon font: Flaticon
    Creation date: 13/11/2017 10:01
    */

    @font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-breakfast-1:before { content: "\f100"; }
.flaticon-food-2:before { content: "\f101"; }
.flaticon-food-1:before { content: "\f102"; }
.flaticon-food:before { content: "\f103"; }
    
    $font-Flaticon-breakfast-1: "\f100";
    $font-Flaticon-food-2: "\f101";
    $font-Flaticon-food-1: "\f102";
    $font-Flaticon-food: "\f103";